import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useMediaQuery, Theme } from '@mui/material';
import type { GraphQLResult } from '@aws-amplify/api';
import { Auth } from 'aws-amplify';

import { RootState } from 'states';
import { Patient, LastClickedButtonType } from 'states/ducks/patients/types';
import { updatePatient } from 'states/ducks/patients/slice';
import { initialPatient } from 'constant';
import { generateQrCodeArray } from 'utils';
import {
  footerButtonRequest,
  verifyPasswordPolicyRequest,
} from 'utils/externalApiHelper';
import { shouldShowAmbulanceRecordButton } from 'utils/ambulanceRecordButtonJudgeHelper';
import { ApiRequestType } from 'views/components/Dashboard/src/Footer/src/FooterButton';
import {
  InvitationsModel,
  GetInvitationUrlQueryType,
} from 'effects/src/InvitationsEffect';
import { clearAuth } from 'states/ducks/auth/slice';
import { clearHospital } from 'states/ducks/hospital/slice';
import { clearVideoCallInvitations } from 'states/ducks/invitations/slice';
import { clearPatients } from 'states/ducks/patients/slice';
import { clearImages } from 'states/ducks/s3images/slice';
import { toggleOpenMessages } from 'states/ducks/messages/slice';

export const useBreakPoints = () => {
  const isTabletSize = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });
  return { isTabletSize };
};

export const useUsername = () => {
  return useSelector((state: RootState) => state.auth.username);
};

export const usePassword = () => {
  return useSelector((state: RootState) => state.auth.password);
};

export const useHospital = () => {
  return useSelector((state: RootState) => state.hospital.hospital);
};

export const useInvitationIds = () => {
  return useSelector((state: RootState) => state.invitations.ids as string[]);
};

export const useInvitation = (id: string) => {
  return useSelector((state: RootState) => state.invitations.entities[id]);
};

export const useGetInvitationUrl = (id: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const getInvitationUrl = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = (await InvitationsModel.getInvitationUrl(
        id
      )) as GraphQLResult<GetInvitationUrlQueryType>;
      setIsLoading(false);
      return response.data?.getInvitationUrl?.url;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
    return undefined;
  }, [id]);
  return { getInvitationUrl, isLoading };
};

export const useMessages = () => {
  const { messages, isOpenMessages } = useSelector((state: RootState) => state.messages);
  const dispatch = useDispatch();
  return { messages, isOpenMessages, toggleOpen: () => dispatch(toggleOpenMessages()) };
};

export const usePatientsState = () => {
  return useSelector((state: RootState) => state.patients);
};

export const useSelectedPatient = (): Patient => {
  const { selectedPatientId } = usePatientsState();
  const patient = useSelector(
    (state: RootState) => state.patients.entities[selectedPatientId]
  );
  return patient === undefined ? initialPatient : patient;
};

export const useQrCodeCsvString = () => {
  const patient = useSelectedPatient();
  return useMemo(() => generateQrCodeArray(patient).qrCodeCsvString, [patient]);
};

export const useQrCompressionFlag = () => {
  const { qr_compression_flag } = useHospital();
  return qr_compression_flag;
};

export const useVisibleFooter = (): boolean => {
  const { inquiry_response_button_flag } = useHospital();
  const { simultaneousInquiry } = useSelectedPatient();

  return inquiry_response_button_flag === true && simultaneousInquiry === true;
};

export const usePrintAmbulanceRecordButton = (): {
  isVisible: boolean;
  label: string;
} => {
  const { ambulance_record_button_flag } = useHospital();

  // 移行期間対応処理 ---------------------------------------------------------------------------------------
  const { hpUID } = useHospital();

  if (ambulance_record_button_flag === undefined) {
    if (shouldShowAmbulanceRecordButton(hpUID ?? '')) {
      // hpUIDがホワイトリストにあるとき、「活動記録票」ボタンを表示する
      return { isVisible: true, label: '活動記録票' };
    }
  }
  // ここまで ----------------------------------------------------------------------------------------------

  if (ambulance_record_button_flag) {
    return {
      isVisible: true,
      label: '活動記録票',
    };
  }

  return { isVisible: false, label: '' };
};

export const useDoctorHeliInquiry = () => {
  // V2移行期間対応処理 -------------------------------------------------------------------------------------
  const { hpUID } = useHospital();
  const whiteList = [
    'B973BEF5-DF15-3447-FC1E-B2E214D54DAE', // 藁【テスト】TXP三次病院
    'E34171A4-1794-B949-967F-11907C30DEDA', // 藁【テスト】広島テスト病院(本番ダッシュボード検証)
    '5A77C3D3-8FBE-484F-A831-36D1BB676DDB', // 広島大学病院
    'F920ECB0-0A52-224D-A918-F8AFDB8C4887', // 広島大学病院(2次)
    'F77577A9-D1F9-724D-995D-8DAFFB4528D4', // 広島市立広島市民病院（3次）
    '00F1563E-DD58-A948-A16E-D355FBADC9E3', // 県立広島病院
    '6B2CE112-3841-5940-86B4-A0B3918452CD', // 広島市立北部医療センター安佐市民病院
    'CF67A487-D85D-A947-AFFB-90FCA808DA03', // 広島赤十字・原爆病院
    '0A860E05-05E9-8543-8E3D-CD6A7E261772', // 市立三次中央病院
    'C93828F4-348A-AD46-9315-D7761C6443AB', // JA広島総合病院
    '4931D939-5563-4E43-838F-F913CD8FAFD8', // 中国労災病院
    'F5459D14-3B5A-6042-AEB8-31AFDEAB7BE6', // 呉医療センター
    '6B2CE112-3841-5940-86B4-A0B3918452CD', // 広島市立北部医療センター安佐市民病院
    'A0DD0193-0394-1743-88B2-61CA677494C2', // 庄原赤十字病院
    '1AE23540-38A8-E04A-9739-6825A7991FA9', // 興生総合病院
    '1144A529-761B-C440-AA84-77010BC87690', // JA尾道総合病院
    'C6F626C9-6BC3-034D-8935-009F15D17320', // 三原赤十字病院
    'E618CEC9-574F-774F-9482-FF1E524D82C4', // 福山市民病院
  ];
  const doctor_heli_inquiry_flag =
    hpUID != null && whiteList.includes(hpUID.toUpperCase());
  return doctor_heli_inquiry_flag;
  // V2認証移行後はGraphQLからフラグを取得する予定
  // ここまで ----------------------------------------------------------------------------------------------
};

export const useDoctorCarInquiry = () => {
  // V2移行期間対応処理 -------------------------------------------------------------------------------------
  const { hpUID } = useHospital();
  const whiteList = [
    'B973BEF5-DF15-3447-FC1E-B2E214D54DAE', // 藁【テスト】TXP三次病院
    'B303383D-77C5-421E-91C6-638C34392D34', // 藁【テスト】札幌テスト病院(本番ダッシュボード検証用)
    '1E1362BF-192C-0F49-BF56-5350B0581571', // 市立札幌病院
    '5F6195A1-FB3C-D64D-9BED-7148C8BDCEE4', // 北海道大学病院
    '56625A0C-8CC7-1F4A-A562-AFE116D30694', // 手稲渓仁会病院
    'B194E17B-6763-0044-A09F-15128AC57E82', // 札幌医科大学附属病院
    '4B0D1D22-F5C3-9043-ACCB-5EF0DBB49BC7', // 北海道医療センター
  ];
  const doctor_heli_inquiry_flag =
    hpUID != null && whiteList.includes(hpUID.toUpperCase());
  return doctor_heli_inquiry_flag;
  // V2認証移行後はGraphQLからフラグを取得する予定
  // ここまで ----------------------------------------------------------------------------------------------
};

export const useSilius = () => {
  // V2移行期間対応処理 -------------------------------------------------------------------------------------
  const { hpUID } = useHospital();
  const whiteList = [
    '9A351305-5B98-FE49-A090-28578B2A5EF1', // 札幌柏葉会病院
    '28C81C6A-C883-9C4F-B692-0760D42E5204', // 勤医協中央病院
    '3254284A-D6F0-5248-9FFA-E62962D64DB9', // 札幌東徳洲会病院
    '56625A0C-8CC7-1F4A-A562-AFE116D30694', // 手稲渓仁会病院
    '1A9EC9EF-F8D1-DF44-A688-350E8489BAB1', // 札幌徳洲会病院
    'CC029AD4-21D6-C847-92B1-F3ED60272C0F', // 中村記念病院
    '6981BB38-3BB6-A24B-8B44-12DA9E247F25', // 札幌禎心会病院
    '17C18F2C-AAFF-C24E-B352-BC30C620CA7D', // KKR札幌医療センター
    'F562E6EF-4D83-F248-A19F-778B6B5B339C', // 夜間急病センター
    '0D423288-B8E9-194A-A955-1CB2FD019A50', // 札幌孝仁会記念病院
    '1E1362BF-192C-0F49-BF56-5350B0581571', // 市立札幌病院
    '204B4183-F170-1646-A7D9-DC5EF4E95226', // 中村記念南病院
    '4B0D1D22-F5C3-9043-ACCB-5EF0DBB49BC7', // 北海道医療センター
    'A6282A18-94FA-9C4E-81BC-712BD6CE5D27', // 札幌白石記念病院
    '4D895DF1-A6F5-3741-85B8-F9C5F12B95D1', // 北海道病院
    '5344C4A4-241B-134E-A2B5-BBEF10A48720', // 斗南病院
    'A55A9743-2663-E547-801D-F86FB77F279F', // NTT東日本札幌病院
    '0B36F65A-CC05-2946-9042-DCB2786632CD', // 札幌厚生病院
    '38E5ECFD-5D12-E845-A752-DC1B35E5AB27', // 札幌中央病院
    '15E8B913-1E51-1F48-B484-BA7F018880B5', // 天使病院
    '344DAA16-C56C-8547-9501-BA66480C1ED3', // 柏葉脳神経外科病院
    'ADE95674-0709-F94E-93BE-864263EDCDF9', // 札幌麻生脳神経外科病院
    'C4D091E2-A1CC-A74D-92F9-B89009CB3931', // JR札幌病院
    '90771608-C16D-E24C-9B83-A3EB7982F4B8', // 北海道脳神経外科記念病院
    '5F6195A1-FB3C-D64D-9BED-7148C8BDCEE4', // 北海道大学病院
    '8EE94984-58AF-9941-8766-40D19F48C949', // 札幌北辰病院
    'B724550C-DCBA-624E-9391-9736E6EAF8D5', // 札幌ハートセンター心臓血管クリニック
    '31B0BE83-CF22-564A-9D33-06ABAC4B2A46', // 土田病院
    'ED21ED66-7E01-0B43-9B69-488403E9852E', // イムス札幌消化器中央総合病院
    'EA398D0D-2043-D64C-8DF9-141D86B334E4', // 札幌宮の沢脳神経外科病院
    '562158F4-B90D-0A40-94A4-6B224DBDF725', // 新さっぽろ脳神経外科病院
    '43971D87-3899-4D44-8B18-2E084D1FBE99', // 時計台記念病院
    '8380A9A6-9F2B-5C4A-A3F6-E70472A38B2E', // 札幌秀友会病院
    '866E2441-C777-D241-A918-D9950C980589', // 札幌真駒内病院
    '133C6728-675C-164A-8B7D-C0BCAF1093BA', // 札幌共立五輪橋病院
    '1AEE8D14-7CC3-5D4C-92B1-32123615B045', // 静和記念病院
    'B94F1CE6-7360-FD45-A48E-53B1A0621948', // 北海道循環器病院
    'F2D6C3FD-04A7-C64E-A23C-DD7B1EF2D544', // 札幌北楡病院
    '45FD9FFC-F840-2A47-8294-4B41B2CFA5D5', // 札幌美しが丘脳神経外科病院
    '0F9AD245-BB35-844C-9BEE-075A7E90E007', // 札幌整形循環器病院
    'B194E17B-6763-0044-A09F-15128AC57E82', // 札幌医科大学附属病院
    '4F8D48F8-BD5F-674B-BC84-96CFE3966BF2', // 北光記念病院
    'BF7932F3-6713-C147-A9B5-3453B84076DE', // 恵佑会札幌病院
    '0288A5C3-E406-1745-A9FB-99739C9D43F3', // 華岡青洲記念病院
    'DEB749D1-A2B7-9148-BF8A-E13BB8503DE0', // 三樹会泌尿器科病院
    'DBF52D90-36E8-1D41-9032-262361A98B09', // 北海道泌尿器科記念病院
    '1013F5CE-083E-2E4D-BA19-C8C32AFA9D38', // 愛育病院
    '075AC648-3072-9D4C-BB78-4438CF583ABA', // 自衛隊札幌病院
    '7E3AD31F-08D5-3945-A9EF-1CA555678D36', // いとう整形外科病院
    'C31B8C90-E6BB-C143-803F-B2B62C0A8D4F', // 札幌循環器病院
    '6976A04A-5DD0-FE4E-ADCB-96787EE60FAE', // 札幌南整形外科病院
    'FE2A9889-6D17-344C-A7D7-FFE0C0EA5D5E', // 札幌清田病院
    '3F2369A4-EF09-904B-B0CA-C2DA17C5DE49', // 五輪橋整形外科病院
    '03CBD63F-57FF-AC4D-90D3-A6EDE743587A', // 愛心メモリアル病院
    'CC7D926A-B59C-3141-9418-A7840A81068A', // 松田整形外科記念病院
    'D4D1B063-2D28-E84F-ABEF-D9D495A37727', // 新札幌循環器病院
    'AE81B16C-F5A6-2049-A60F-286DA97F182A', // 坂泌尿器科病院
    '87887F76-C1C5-1E4D-B3EC-64285F55DEE0', // 北海道循環器病院
    'F95FE251-C35C-7C4E-9C8B-531C86E228AD', // 北海道大学病院
    'A6BA843F-503C-6741-A41C-AD57C188F32A', // 整形外科北新病院
    '000961AF-EA8D-0342-91B0-6831E1CB801B', // 北成病院
    '8772C750-7CD4-354E-8536-7AB387367D48', // 札幌里塚病院
    'BBC9ED82-D6B1-1544-8A70-B28347886E92', // 新川新道整形外科病院
    '17371984-D1B1-B64E-8E12-6F6AAD0A01FC', // 北海道整形外科記念病院
    '5C172310-2F9B-6449-BBA9-2824EAED5A11', // 西さっぽろ病院
    '2C93B0E6-1D92-0644-BD8E-4D9A73DBA75E', // 札幌北脳神経外科
    'C205F9EF-6CB1-6040-B8CF-A875FF7C3D29', // 仁楡会札幌病院
    'DE32BFE2-881A-4646-A684-A61BE4BE505F', // 札幌外科記念病院
    'D7182B67-D892-E04F-9224-6F92497709F1', // 札幌円山整形外科病院
    '4AAACA25-D78C-8F40-AA78-0DB8C8092432', // 札幌清田整形外科病院
    'B5AC205E-A824-BC4C-8CBD-829609458E92', // 麻生整形外科病院
    '3479A458-6972-494B-AE0A-7903E67C3CEF', // コスモ脳神経外科
    '4E8FB598-56BD-0E43-87B4-86853EFFBE9D', // 中田泌尿器科病院
    '785C8C0D-E7B6-7B43-BD95-D5AEDFA0734B', // クラーク病院
    'F9B712F2-C278-DA4F-86CF-1F1098ACC934', // 札幌東豊病院
    '654EC1D7-FB34-8B4C-BF5E-A6C81D3712A3', // 西岡第一病院
    '21E4FA0A-48F9-0840-B26A-4289244E5DB7', // 西岡病院
    '70E51D03-4962-4141-87F0-A582CC0EDD72', // 石垣整形外科医院
    'AF4BCB44-492E-B045-908A-6C446EB9B66F', // 中野整形外科医院
    '7E7786AF-1025-1542-AE59-19F0A70F3FDE', // 札幌マタニティ・ウイメンズホスピタル
    'CE5118A2-F998-5244-ACDA-12D849F1B8E3', // 札幌センチュリー病院
    '5EC62536-0880-1641-AC40-9130CE37131B', // 札幌第一病院
    'C9292A11-BCE0-AB4C-8B47-3E00FC10C89B', // ていね泌尿器科
    'D4DE16D5-D5BB-0142-AD4B-70C5191CB071', // 北央病院
    'EAF10541-0159-2B42-B2AD-05F0F4ABE4CE', // 石川泌尿器科・腎臓内科
    '3DDFD20B-A85E-9347-A64A-883643D58569', // 北札幌病院
    '1AAA49DB-9415-7B4D-82B1-0DF3E626F13B', // 新札幌豊和会病院
    '3075F248-1F94-8949-9CB3-7EA3DDF118DB', // 札幌優翔館病院
    'B164C823-D391-0B44-9455-D7AC21CBB884', // 東札幌病院
    'B303383D-77C5-421E-91C6-638C34392D34', // 藁【テスト】札幌テスト病院(本番ダッシュボード検証用)
  ];
  const silius_flag = hpUID != null && whiteList.includes(hpUID.toUpperCase());
  return silius_flag;
  // V2認証移行後はGraphQLからフラグを取得する予定
  // ここまで ----------------------------------------------------------------------------------------------
};

export const useHospitalName = () => {
  const { hospitalName } = useHospital();
  return hospitalName;
};

export const useActiveCpa = () => {
  const { cpf } = useSelectedPatient();
  if (cpf[0] === '1') return true;
  else return false;
};

export const useClientDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [clientDimensions, setClientDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setClientDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return clientDimensions;
};

export const useFooterButtonHandler = (
  ambulancerecord_UID: string,
  hospital_UID: string,
  action: LastClickedButtonType | undefined,
  responseType: ApiRequestType
) => {
  const dispatch = useDispatch();
  const { hostUrl } = useHospital();
  const [isOpen, setOpen] = useState(false);

  const handleSubmit = useCallback(
    async (patient: Patient) => {
      const addedLastClickedPatient = { ...patient, lastClicked: action };
      dispatch(updatePatient(addedLastClickedPatient));
      setOpen(true);
      await footerButtonRequest(ambulancerecord_UID, responseType, hospital_UID, hostUrl);
    },
    [ambulancerecord_UID, dispatch, hospital_UID, action, responseType, hostUrl]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return { isOpen, handleSubmit, handleClose };
};

export const useSubscriptionError = () => {
  return useSelector((state: RootState) => state.error.subscriptionError.errorByOR);
};

export const useSignOut = () => {
  const dispatch = useDispatch();

  return async () => {
    localStorage.clear();
    dispatch(clearAuth());
    dispatch(clearHospital());
    dispatch(clearVideoCallInvitations());
    dispatch(clearPatients());
    dispatch(clearImages());
    await Auth.signOut();
  };
};

export const useChangePassword = async (
  hpUID: string,
  previousPassword: string,
  proposedPassword: string,
  confirmPassword: string
) => {
  // Localで判定できるチェック
  let errors: string[] = [];
  if (previousPassword === '') {
    errors.push('Previous password not entered.');
  }
  if (proposedPassword === '') {
    errors.push('Proposed password not entered.');
  }
  if (confirmPassword === '') {
    errors.push('Confirm password not entered.');
  }
  if (errors.length !== 0) {
    return { result: false, errors };
  }
  if (proposedPassword !== confirmPassword) {
    return { result: false, errors: ['Proposed passwords do not match.'] };
  }

  // パスワードポリシー判定
  const verify = await verifyPasswordPolicyRequest(hpUID, proposedPassword);
  if (verify === false) {
    return {
      result: false,
      errors: ['Proposed password does not mutch password policy.'],
    };
  }

  // パスワード変更API実行
  try {
    const user = await Auth.currentAuthenticatedUser(); // ログイン中のユーザー情報
    await Auth.changePassword(
      user,
      previousPassword, // 現在のパスワード
      proposedPassword // 新しいパスワード
    );
  } catch (exception) {
    let error = '';
    switch (exception.code) {
      case 'NotAuthorizedException':
        error = 'Previous password is incorrect.';
        break;
      case 'InvalidPasswordException':
        error = 'Proposed password does not mutch password policy.';
        break;
      case 'LimitExceededException':
        error = 'API limit exceeded.';
        break;
      default:
        console.log(exception);
        error = 'Uknown exception.';
        break;
    }
    return { result: false, errors: [error] };
  }
  return { result: true, errors: [] };
};
